<template>
  <v-container style="margin-bottom: 100px">
    <customer-info logo="true" dayDateWithBackBtn="true" customer="true" />

    <v-row>
      <v-col md="8" class="d-flex">
        <h1>PROJECT MATERIALS</h1>
      </v-col>

      <v-col md="4" class="text-right">
        <v-btn color="primary" dark @click="dialog = true">Add </v-btn>
      </v-col>
    </v-row>

    <datatable
      :headers="headers"
      :staticData="items"
      :componentURL="componentURL"
      :search="false"
    ></datatable>

    <v-dialog v-model="dialog" max-width="500" persistent>
      <v-card>
        <v-container>
          <div class="popup-wrap">
            <div class="d-flex justify-space-between">
              <div class="popup-title">
                <h6>Add Material</h6>
              </div>

              <v-btn text @click.native="dialog = false">
                <v-img
                  src="../../assets/close-icon.png"
                  alt="close-icon"
                ></v-img>
              </v-btn>
            </div>

            <div class="popup-wrap-inner step-one">
              <div class="customer-form">
                <v-form v-model="valid" ref="form" lazy-validation>
                  <v-row class="px-5">
                    <v-col cols="12">
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            label="Quantity"
                            min="1"
                            type="number"
                            required
                            v-model="addMaterials.qty"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-select
                            label="UOM"
                            required
                            :items="uomValues"
                            v-model="addMaterials.uom"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            label="Item"
                            required
                            v-model="addMaterials.item"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <div class="text-right">
                            <v-btn color="primary" dark @click="saveFormData">
                              Add
                            </v-btn>
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-form>
              </div>
            </div>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Datatable from "../../components/Datatable/Datatable";
import CustomerInfo from "./CustomerInfo";

export default {
  name: "TodayMaterials",
  components: { CustomerInfo, Datatable },
  data() {
    return {
      componentURL: "",
      dialog: false,
      valid: false,
      headers: [
        { text: "QTY", value: "qty" },
        { text: "UOM", value: "uom" },
        { text: "Item", value: "item" },
      ],
      items: [
        {
          qty: "22",
          uom: "Each",
          item: "6x6",
        },
        {
          qty: "1",
          uom: "Each",
          item: "Basin",
        },
        {
          qty: "1",
          uom: "Each",
          item: "Bondo",
        },
        {
          qty: "1",
          uom: "Each",
          item: "C33",
        },
        {
          qty: "1",
          uom: "Ea",
          item: "c8",
        },
        {
          qty: "1",
          uom: "Bag",
          item: "ConcreteBag",
        },
      ],
      uomValues: [
        "Bag",
        "Bucket",
        "Bundle",
        "Bowl",
        "Box",
        "Card",
        "Centimeters",
        "Case",
        "Carton",
        "Dozen",
        "Each",
        "Foot",
        "Gallon",
        "Gross",
        "Inches",
        "Kit",
        "Lot",
        "Meter",
        "Millimeter",
        "Piece",
        "Pack",
        "Pack 100",
        "Pack 50",
        "Pair",
        "Rack",
        "Roll",
        "Set",
        "Set of 3",
        "Set of 4",
        "Set of 5",
        "Single",
        "Sheet",
        "Square ft",
        "Tube",
        "Yard",
      ],
      addMaterials: {
        qty: "",
        uom: "",
        item: "",
      },
    };
  },
  methods: {
    saveFormData() {
      let valid = this.$refs.form.validate();
      if (valid) {
        this.items.push(this.addMaterials);
        this.dialog = false;
        this.addMaterials = { qty: "", uom: "", item: "" };
        this.reset = true;
      }
    },
  },
};
</script>

<style scoped>
.popup-title {
  padding: 0px 18px !important;
  text-align: center;
}

.popup-wrap {
  padding: 20px 0;
}
</style>
